<template>
<div>
	<el-dialog :title="dialogTitle" :visible.sync="dialogDocVisible" :close-on-click-modal="false">
		<div class="doc-box">
			<div v-html="info.content"></div>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
			dialogTitle:'',
			dialogDocVisible:false,
			info:{
				id:'',
				title:'',
				content:''
			},
        }
    },
    created() {
		
    },
    mounted() {
		
    },
    methods:{
		getDoc(id,title=""){
			this.dialogTitle =  title
			this.$api.get('UsageDoc/edit',{id:id},res=>{
				if(res.code == 200){
					this.info = res.data
					this.content =  res.data.content
					if(!title){
						this.dialogTitle =  res.data.title
					}
					this.dialogDocVisible =  true
				}else{
					this.$message.error('数据获取异常')
				}
			})
		}
    },
}
</script>
<style scoped lang="scss">
	.doc-box{
		// overflow: scroll;
		overflow-y: auto;
		height: 500px;
		img{
			width: 100%;
		}
		::v-deep img{
			max-width: 100%;
		}
	}

</style>