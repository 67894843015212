<template>
<div class="page-container">
	<p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
		 <span v-if="form.status == 1">
			 1、通过选择一个或者多个字段的组合，来设置{{form.type == 1?'资产编码':'物料编码'}}规则
			 2、当前仅支持系统自动生成{{form.type == 1?'资产编码':'物料编码'}}，如需手动生成编码，请点击
		 </span>
	    <span v-if="form.status == 2">
			当前仅支持手工输入"资产编码" ,如需自动生成请点击
		</span>
		  <el-button type="primary" size="small" plain @click="updateStatus">{{form.status == 1 ?'关闭':'开启'}}</el-button>
	 </div>  
	 <div style="height: 500px;" v-if="form.status == 1">
		  <el-form ref='form' :model="form" prop="" label-width="100px">
				<div class="asset-code-transfer">
					<tree-transfer ref="tree" :title="title" :defaultCheckedKeys="defaultCheckedKeys" 
						:defaultTransfer="defaultTransfer"   :from_data='fromData'  :to_data='toData' :defaultProps="{label:'label'}"
						:allow-drag="allowDrag"  :allow-drop="allowDrop" 
						@add-btn='add' @remove-btn='remove' :mode='mode'  height="540px" filter openAll draggable>
					</tree-transfer>
				</div>
				<el-form-item label="自定义文本" style="margin-top: 10px;" v-if="customTextStatus">
				    <el-input  v-model="form.custom_text" style="width: 230px;"></el-input>
				</el-form-item>
				<el-form-item label="流水号长度" prop="sn_len" style="margin-top: 10px;">
				    <el-input type="number"  v-model="form.sn_len" :min="3" :max="10" style="width: 230px;" @change="changeSnLen"></el-input>
				</el-form-item>
				<el-form-item label="流水号类别" prop="sn_type">
				    <template>
				        <el-radio v-model="form.sn_type" :label="1">大流水号</el-radio>
				        <el-radio v-model="form.sn_type" :label="2">小流水号</el-radio>
				    </template>
					<i class="el-icon-warning-outline"  @click="question(11)"></i>
				</el-form-item>
				<el-form-item label="连字符" prop="splice_str">
					<el-select v-model="form.splice_str" placeholder="请选择" clearable @change="handleSelectChange">
					     <el-option label='-' 	value="-"></el-option>
						 <el-option label='_' 	value="_"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="预览效果">
					<span class="preview">{{preview}}</span>
				</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		      <el-button type="primary" size="small" @click="save()">保 存</el-button>
		  </div>
		<DocVue ref="docDialog"></DocVue>
	</div>
</div>
</template>

<script>
import treeTransfer from 'el-tree-transfer' // 引入
import DocVue  from '../Doc.vue'	
export default {
	components: {
		treeTransfer,
		DocVue
	},
	inject: ["reload"],
    data() {
        return {
			title:["可选字段","已选字段"],
			count:0,
			mode: "transfer", // transfer addressList
			toData: [],
			fromData:[],
			preview:'',
			customTextStatus:false,
			form:{
				id:'',
				sn_len:5,
				sn_type:1,
				splice_str:'-',
				field_keys:[],
				custom_text:'',
				status:1,			//0-自动生成编码  1-手动生成编码
				type:1,
			},
			defaultTransfer:false,
			defaultCheckedKeys:[1],
			allowDrop:function(draggingNode,dropNode,type){
				if(dropNode.data.id == 1){
					this.$message.error("默认流水号字段无法拖拽")
					return false
				}else{
					return true
				}
				
			},
			allowDrag:function(node){
				if(node.data.id == 1){
					this.$message.error("默认流水号字段无法拖拽")
					return false
				}else{
					return true
				}
			
			},
			default_todata:{
				id:0,
				label:'流水号',
				disabled:true
			}
        }
    },
	watch:{
		$route:{
			immediate:true,
			handler(to,from){
				// 1-资产分类  2-耗材分类
				this.form.type =  to.meta.code_type
				this.getFieldData()
			}
	   } 	
	},
    created() {
		// this.getFieldData()
    },
    mounted() {

    },
    methods:{
		//字段数据列表
		getFieldData(){
			
			this.$api.get('AssetCode/index',{type:this.form.type},res=>{
				if(res.code == 200){
					this.fromData =  res.data.field_list
					this.count =   res.data.field_list.length
					if(res.data.info){
						this.defaultCheckedKeys =  res.data.info.field_keys
						this.form =  res.data.info
					}else{
						this.form.status = 0
					}
					if(this.form.status == 1){
						this.$nextTick(function(){
							this.$refs.tree.addToAims(false)
							var list  = this.$refs.tree.getChecked()
						})
					}
					
				}
			})
		},
		handleSelectChange(val){
			var arr = []
			this.toData.forEach((item,index)=>{
				arr.push(item.label)
			})
			 this.preview = arr.join(this.form.splice_str)   	//预览字段
		},

		// 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
		changeMode() {
			console.log('mode')
			if (this.mode == "transfer") {
				this.mode = "addressList";
			} else {
				this.mode = "transfer";
			}
		},
		// 监听穿梭框组件添加
		add(fromData, toData, obj) {
			console.log('add')
			// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
			// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
			// console.log("fromData:", fromData);
			// console.log("toData:", toData);
			// // console.log("obj:", obj);

			toData.forEach((item,index)=>{
				 if(item.id == 1){		//将流水号始终置于底部
					let default_data =  item
					toData.splice(index,1)
					toData.push({id:default_data.id,label:default_data.label,disabled:default_data.disabled})
				}
			})
			this.setCustomStatus(toData)

		},
		// 监听穿梭框组件移除
		remove(fromData, toData, obj) {
			// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
			// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
			// console.log("fromData:", fromData);
			// console.log("toData:", toData);
			// console.log("obj:", obj);
			this.setCustomStatus(toData)
		},	
		question(id){
			this.$refs.docDialog.getDoc(id)
		},
		
		updateStatus(){
			console.log('updateStatus:',this.form)
			let status = this.form.status == 1?2:1
			this.$api.put('AssetCode/updateStatus',{status:status,type:this.form.type},res=>{
				if(res.code == 200){
					this.$message.success(res.msg)	
					this.reload();
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		
		//自定义文本的显隐
		setCustomStatus(data){
			this.form.field_keys  = []
			var arr = []
			data.forEach((item,index)=>{
				this.form.field_keys.push(item.id)
				arr.push(item.label)
			})
			 this.preview = arr.join(this.form.splice_str)   	//预览字段
			if(( $.inArray(this.count,this.form.field_keys)) > -1){
				this.customTextStatus  = true
			}else{
				this.customTextStatus  = false
			}
		},
		
		save(){
			this.$api.put('AssetCode/update',this.form,res=>{
				if(res.code == 200){
					this.$message.success(res.msg)	
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		changeSnLen(val){
			if(val < 3 || val > 10){
				this.$message.error("流水号长度在3-10之间")
				this.form.sn_len =  5
				return false
			}
		}
    },
}
</script>
<style  lang="scss">
	.asset-code-transfer{
		width: 600px;
		.wl-transfer{
			height: 620px !important;
			.transfer-right,.transfer-left{
				background-color: white !important;
			}
			.transfer-title{
				background-color: white;
			}
		}
		.transfer-main{
			overflow: hidden !important;
		}
		.el-tree{
			.el-tree-node{
				background-color: #f9f9f9;
				margin: 3px 0px;
				height: 35px;
				line-height: 35px;
				.custom-tree-node{
					font-size: 12px;
				}
			}
		}
	
		
		// .el-transfer-panel{
		// 	width: 300px !important;
		// 	.el-transfer-panel__header{
		// 		background: white;
		// 		.el-checkbox__label{
		// 			font-size: 14px;
		// 			font-weight: bold;
		// 		}
				
		// 	}
		// 	.el-transfer-panel__body{
		// 		height: 520px;
		// 		.el-checkbox-group{
		// 			height: 100%;
		// 			overflow: hidden;
		// 			width: 95%;
		// 			margin: 0 auto;
		// 			.el-transfer-panel__item{
		// 				// width: 100%;
		// 				margin: 0 auto;
		// 				background-color: #f9f9f9;
		// 				margin-top: 5px;
		// 				height: 35px;
		// 				line-height: 35px;
		// 				.el-checkbox__label{
		// 					font-size: 12px;
		// 					color: #606266;
		// 				}
			
		// 			}
		// 		}
		// 	}
		// 	.el-transfer-panel__footer{
		// 		text-align: center;
		// 	}
		// }
	}
</style>